<template>
  <div>
    <div class="mb-4">
      <h1 class="font-bold text-2xl">Users</h1>
    </div>
    <div class="w-full flex items-center justify-end">
      <button class="btn-sm-primary" @click.stop="addUser">Add</button>
    </div>
    <div>
      <d-table
        class="my-4"
        :headers="headers"
        :items="users"
        wrapperClasses="min-h-screen overflow-y-scroll"
      />
    </div>
  </div>
</template>

<script>
import AddUser from '@/components/apps/users/add-user'
import RemoveUser from '@/components/apps/users/remove-user'
import _headers from './headers'

export default {
  name: 'users',

  meta: {
    title: 'Users',
  },

  data: () => ({
    users: [],
  }),

  computed: {
    headers() {
      return _headers(this)
    },
  },

  async mounted() {
    this.users = await this.$http.get('/api/v2/all-users')
  },

  methods: {
    async fetchData() {
      this.users = await this.$http.get('/api/v2/all-users')
    },
    removeUser(user) {
      const fetchData = this.fetchData
      this.$$showModal({
        component: RemoveUser,
        props: {
          user,
        },
        events: {
          onClose: fetchData,
        },
      })
    },
    addUser() {
      const fetchData = this.fetchData
      this.$$showModal({
        component: AddUser,
        events: {
          onClose: fetchData,
        },
      })
    },
  },
}
</script>
