import { headerItem } from '@/utils/table'
import Button from '@/components/daisy/atoms/button/index.vue'
export default function headers(_this) {
  return [
    headerItem('ID', 'id', {
      generator: (user) => {
        return { value: user._id }
      },
    }),
    headerItem('Account', 'account', {
      generator: (user) => {
        return { value: `${user.name} ${user.isAdmin ? '(ADMIN)' : ''}` }
      },
    }),
    headerItem('Email', 'email', {
      generator: (user) => {
        return { value: user.email }
      },
    }),
    headerItem('Action', 'action', {
      generator: (user) => {
        return {
          component: Button,
          props: {
            text: 'Remove',
            disabled: !!user.isAdmin,
            size: 'sm',
            class: 'btn btn-error btn-xs',
          },
          events: {
            click() {
              _this.removeUser(user)
            },
          },
        }
      },
    }),
  ]
}

