<template>
  <div>
    <div class="font-medium text-lg">Remove {{ user.name }}</div>
    <div class="text-error my-2 text-2xl font-semibold w-full">
      Are you sure?
    </div>

    <div class="modal-action">
      <button class="btn btn-sm" @click="$$closeModal">Cancel</button>
      <button class="btn btn-sm btn-error" @click.stop="push">Remove</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'create-edit-group',

  props: {
    user: {
      type: Object,
      required: true,
    },
  },

  methods: {
    async push(event) {
      if (this.user._id) {
        await this.$http.delete(`/api/v2/remove-user/${this.user._id}`)
      }
      this.$$closeModal(event)
    },
  },
}
</script>
