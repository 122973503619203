<template>
  <div>
    <div class="font-medium text-lg">Add user</div>
    <div class="form-control" v-for="config in configs" :key="config.key">
      <label class="label">
        <span class="label-text">
          {{ config.label }}
          <span class="label-text text-error"> (*) </span>
        </span>
      </label>
      <input
        :type="config.type || 'text'"
        :class="error && !input[config.key] ? 'input-error' : ''"
        class="input input-bordered w-full border-solid"
        v-model.trim="input[config.key]"
      />
    </div>

    <div v-if="error" class="text-error my-2 font-bold text-right w-full">
      Missing required fields
    </div>

    <div class="modal-action">
      <button class="btn btn-sm" @click="$$closeModal">
        {{ $t('Cancel') }}
      </button>
      <button class="btn-sm-primary" @click.stop="push">
        {{ $t('Create') }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'create-edit-group',

  data: () => ({
    id: null,
    error: false,
    input: {
      name: '',
      password: '',
      email: '',
    },
  }),

  computed: {
    configs() {
      return [
        {
          key: 'name',
          label: 'Account Name',
        },
        {
          key: 'password',
          type: 'password',
          label: 'Password',
        },
        {
          key: 'email',
          label: 'Email',
          type: 'email',
        },
      ]
    },
  },

  methods: {
    validate(payload) {
      if (payload.email && payload.password && payload.name) {
        return true
      }
      return false
    },
    async push(event) {
      const payload = this.input
      this.error = !this.validate(payload)
      if (!this.error) {
        await this.$http.post('/api/v2/create-user', payload)
        this.$$closeModal(event)
      }
    },
  },
}
</script>
